import React from 'react';
import crewtimer from './crewtimer.svg';
import gate from './gate.svg';
import entazza from './entazza.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <table style={{ margin: 32 }}>
          <tr>
            <td>
              <img src={entazza} className="App-logo" alt="logo" />
            </td>
            <td>
              <img src={crewtimer} className="App-logo" alt="logo" />
            </td>
            <td>
              <img src={gate} className="App-logo" alt="logo" style={{ background: 'white' }} />
            </td>
          </tr>
          <tr>
            <td>
              <a className="App-link" href="about-entazza.html" target="_blank" rel="noopener noreferrer">
                About Entazza LLC
              </a>
            </td>
            <td>
              <a
                className="App-link"
                href="https://admin.crewtimer.com/about"
                target="_blank"
                rel="noopener noreferrer"
              >
                About CrewTimer
              </a>
            </td>
            <td>
              <a className="App-link" href="about-ourgatecontrol.html" target="_blank" rel="noopener noreferrer">
                About Our Gate Control
              </a>
            </td>
          </tr>
        </table>
      </header>
    </div>
  );
}

export default App;
